<template>
  <div ref="refUiCookies">
    <div
      v-if="props.cookiesBannerVisible"
      class="fixed bottom-0 left-0 z-cookiesBar w-full bg-white py-3 shadow-2xl"
      :class="{ hidden: agreementsModalVisible }"
      data-test="ui-cookies-manager"
    >
      <div class="container">
        <p class="mb-1.5 font-bold text-primary">
          {{ props.labels.cookiesHeader }}
        </p>

        <!-- injected as slot because of <i18n/> component usage -->
        <div class="text-sm [&>a]:text-primary [&>a]:hover:underline">
          <slot name="cookiesText" />
        </div>

        <UiIcon
          name="close"
          :width="16"
          :height="16"
          class="absolute right-2 top-2 cursor-pointer"
          data-test="ui-cookies-manager-close-button"
          @click.prevent="emit('accept-necessary')"
        />
        <div
          class="mt-3 flex flex-col justify-between gap-y-2 md:flex-row md:items-end"
        >
          <UiButton
            variant="secondary"
            data-test="ui-cookies-manager-decline-button"
            @click.prevent="emit('accept-necessary')"
          >
            {{ props.labels.btnUnderstand }}
          </UiButton>
          <div class="flex flex-col gap-y-2 lg:flex-row">
            <UiButton
              variant="secondary"
              class="lg:mr-2"
              data-test="ui-cookies-manager-settings-button"
              @click.prevent="openModal"
            >
              {{ props.labels.btnGoToSetting }}
            </UiButton>
            <UiButton
              variant="action"
              data-test="ui-cookies-manager-accept-button"
              @click.prevent="
                emit('check-accepted-consent', {
                  necessary: true,
                  analytical: true,
                  advertising: true,
                })
              "
            >
              {{ props.labels.btnAccept }}
            </UiButton>
          </div>
        </div>
      </div>
    </div>
    <UiModal
      v-model:open="agreementsModalVisible"
      class="pb-16"
      type="default"
      data-el="modalCookies"
      data-test="ui-cookies-manager-settings-modal"
      @close="handleAgreementsModalClose"
    >
      <template #default>
        <p class="mb-3 text-xl font-bold text-primary">
          {{ props.labels.modalHeader }}
        </p>
        <UiCookiesManagerForm
          :labels="props.labels"
          :cookies-state="cookiesState"
          :cookies-list="cookiesList"
          @submit="emit('check-accepted-consent', $event)"
        >
          <template #btnAcceptedAll>
            <UiButton
              variant="action"
              type="button"
              @click.prevent="
                emit('check-accepted-consent', {
                  necessary: true,
                  analytical: true,
                  advertising: true,
                })
              "
            >
              {{ props.labels.btnAccept }}
            </UiButton>
          </template>
        </UiCookiesManagerForm>
      </template>
    </UiModal>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import UiIcon from '../UiIcon/UiIcon.vue'
import UiButton from '../UiButton/UiButton.vue'
import UiModal from '../UiModal/UiModal.vue'
import UiCookiesManagerForm from './UiCookiesManagerForm/UiCookiesManagerForm.vue'
import type {
  UiCookiesManager,
  UiCookiesManagerState,
} from './UiCookiesManager.types'

const props = defineProps<UiCookiesManager>()

const emit = defineEmits<{
  (e: 'closed-modal'): void
  (e: 'accept-necessary'): void
  (e: 'check-accepted-consent', values: UiCookiesManagerState): void
}>()

const agreementsModalVisible = ref<boolean>(false)

function openModal() {
  agreementsModalVisible.value = true
}
const refUiCookies = ref<HTMLButtonElement>()

function handleAgreementsModalClose() {
  agreementsModalVisible.value = false
  emit('closed-modal')
}

defineExpose({
  ref: refUiCookies,
  openModal,
  handleAgreementsModalClose,
})
</script>
